import request from '@/utils/request'


// 查询消息列表
export function listMessage(query) {
  return request({
    url: '/platform/message/list',
    method: 'get',
    params: query
  })
}

// 查询消息分页
export function pageMessage(query) {
  return request({
    url: '/platform/message/page',
    method: 'get',
    params: query
  })
}

// 查询消息详细
export function getMessage(data) {
  return request({
    url: '/platform/message/detail',
    method: 'get',
    params: data
  })
}

// 新增消息
export function addMessage(data) {
  return request({
    url: '/platform/message/add',
    method: 'post',
    data: data
  })
}

// 修改消息
export function updateMessage(data) {
  return request({
    url: '/platform/message/edit',
    method: 'post',
    data: data
  })
}

// 删除消息
export function delMessage(data) {
  return request({
    url: '/platform/message/delete',
    method: 'post',
    data: data
  })
}
